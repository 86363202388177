@import '../../../../scss/_theme-bootstrap';

.content-block-signup {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  @include breakpoint($landscape-up) {
    height: 90px;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 92%;
    align-items: center;
    padding: 0 15px;
    @include breakpoint($medium-up) {
      flex-direction: row;
    }
  }
  &__content-text {
    font-size: 1rem;
    line-height: 1.4;
    margin-#{$rdirection}: 22px;
    padding: 10px 0;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
    @include breakpoint($landscape-up) {
      width: initial;
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: 20px;
      padding: 0;
    }
    &__mobile {
      font-size: 1rem;
      line-height: 1.4;
      margin-#{$rdirection}: 22px;
      padding: 10px 0;
      @include breakpoint($medium-up) {
        display: none;
      }
      &.content-block-signup--active-success {
        display: none;
      }
    }
    .content-block-signup--column & {
      text-align: center;
    }
    &.content-block-signup--active-success {
      display: none;
    }
    p {
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
  &__disclaimer {
    font-size: 12px;
  }
  &__messages {
    text-align: $ldirection;
    position: absolute;
    bottom: 0;
    .content-block-signup--active-success & {
      position: relative;
    }
    @include breakpoint($medium-up) {
      bottom: 15px;
    }
  }
  &__error {
    transition: all;
    font-size: 12px;
    line-height: 1.5;
    color: $color-error;
    span {
      display: inline-block;
    }
  }
  &__fields {
    width: 75%;
    height: 35px;
    padding-#{$rdirection}: 10px;
    @include pie-clearfix;
    @include breakpoint($landscape-up) {
      min-width: 233px;
    }
    .content-block-signup--active-success & {
      display: none;
    }
    .label.adpl-label {
      padding: 2px 0 0;
      &::before {
        color: $color-primary-900;
      }
    }
  }
  &__button {
    width: 25%;
    @include breakpoint($landscape-up) {
      width: initial;
    }
    .content-block-signup--active-success & {
      width: 0;
    }
  }
  .elc-button--dark {
    height: 43px;
  }
  input[type='email'].content-block-signup__field {
    height: 43px;
    padding-#{$rdirection}: 0;
    width: 100%;
    @include input-placeholder {
      color: $color-black;
      text-transform: none;
    }
    &.error {
      color: $color-error;
      border-bottom: 1px solid $color-error;
    }
    input[type='text'] {
      &.content-block-signup__field {
        padding-#{$rdirection}: 0;
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
    }
  }
  &__submit {
    width: 100%;
    padding: 0;
    @include breakpoint($landscape-up) {
      min-width: 250px;
    }
    .content-block-signup--active-success & {
      display: none;
    }
  }
  &__form {
    display: flex;
    width: 100%;
    @include breakpoint($landscape-up) {
      width: initial;
    }
    &.content-block-signup--active {
      &-error {
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
      &-success {
        justify-content: center;
        padding: 0;
      }
    }
    .content-block-signup--column & {
      text-align: center;
      margin: 0 auto;
      width: 333px;
      padding: 0 0 18px;
    }
  }
  p {
    margin: 0;
  }
}
